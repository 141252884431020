<template>
    <div>

        <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
       
            
                <center><br/>
                    <h1>Alumini Sign-UP</h1>
                </center>
          
      
        <div >



            <v-row class="mx-12" justify="center">
                <v-col cols="12" sm="4" md="4">
                    <div>
                        <v-text-field type="email" :disabled="disable_email" outlined color="primary" variant="solo"
                            v-model="email">
                            <template #label>
                                Email<span style="color:red;font-size: 20px;">*</span>
                            </template>
                        </v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                    <div>
                        <center> <v-btn class="mt-2" @click="Sendotp" :disabled="disable_send_otp_button"
                                :loading="send_otp_loading" color="primary">Send Otp</v-btn></center>
                    </div>
                </v-col>

            </v-row>
        </div>
        <v-row v-if="is_otp_send" class="mx-12" justify="center">
            <v-col cols="12" sm="4" md="4">
                <div>
                    <v-text-field :disabled="disable_otp" outlined color="primary"  v-model="email_otp">
                        <template #label>
                            Email Otp<span style="color:red;font-size: 20px;">*</span>
                        </template>
                    </v-text-field>
                </div>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <div>
                    <center> <v-btn class="mt-2" @click="verifyOTP" :loading="send_otp_loading"
                            :disabled="disable_verify_otp_button" color="green">Verify Otp</v-btn></center>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="is_email_verified" class="mx-12" justify="center">
            <v-col cols="12" sm="4" md="4">
                <div>
                    <v-text-field outlined color="primary"  v-model="first_name">
                        <template #label>
                            First Name<span style="color:red;font-size: 20px;">*</span>
                        </template>
                    </v-text-field>
                </div>
            </v-col>
            <v-col cols="12" sm="4" md="4">
                <div>
                    <v-text-field outlined color="primary"  v-model="middle_name">
                        <template #label>
                            Middle Name
                        </template>
                    </v-text-field>
                </div>
            </v-col>
            <v-col cols="12" sm="4" md="4">
                <div>
                    <v-text-field outlined color="primary"  v-model="last_name">
                        <template #label>
                            Last Name<span style="color:red;font-size: 20px;">*</span>
                        </template>
                    </v-text-field>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="is_email_verified" class="mx-12" justify="center">
            <v-col cols="12" sm="4" md="4">
                <div>
                    <v-text-field type="number" outlined color="primary"  v-model="mobile_no">
                        <template #label>
                            Mobile No<span style="color:red;font-size: 20px;">*</span>
                        </template>
                    </v-text-field>
                </div>
            </v-col>
            
        </v-row>
      
        <v-row no-gutters v-if="is_email_verified" class="mx-12" justify="center">
            <v-col cols="12" sm="4" md="4">
                <div>
                    <v-text-field @click:append-inner="update_password_icon('password')"
                        :append-inner-icon="password_icon" :type="password_type" outlined color="primary"
                         v-model="password" hint="Password Length Must Be Greather than 8 char">
                        <template #label>
                            Password<span style="color:red;font-size: 20px;">*</span>
                        </template>
                    </v-text-field>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="is_email_verified" class="mx-12" justify="center">
            <v-col cols="12" sm="4" md="4">
                <div>
                    <v-text-field @click:append-inner="update_password_icon('confirm_password')"
                        :append-inner-icon="confirm_password_icon" :type="confirm_password_type" outlined color="primary"
                         v-model="confirm_password" hint="Password & Confirm Password Should Be Same">
                        <template #label>
                            Confirm Password<span style="color:red;font-size: 20px;">*</span>
                        </template>
                    </v-text-field>
                </div>
            </v-col>
        </v-row>
  
        <v-row no-gutters v-if="is_email_verified" class="mx-12" justify="center">
            <v-col cols="12" sm="4" md="4">
                <div>
                    <v-checkbox
                        label="I have noted down, mobile number and Email Id for future communication.My Email ID will be used as username in this portal "
                        @change="enable_register" color="primary" v-model="is_agree">

                    </v-checkbox>
                </div>
            </v-col>
        </v-row>

        <v-row v-if="is_email_verified" class="mx-12 ma-5 mb-15" justify="center">
            <v-btn class="" @click="register" :loading="register_loading" :disabled="register_button_disable"
                color="primary">Register</v-btn>

        </v-row>

    </div>
</template>
<script>

import axios from "axios";
import crypto from "../service/crypto";

export default {
    name: "App",

   
    data() {
        return {
            snackbar_msg: "",
            color: "",
            snackbar: false,
            first_name: "",
            last_name: "",
            middle_name: "",
            email: "",
            email_otp: "",
            mobile_no: "",
            is_email_verified: false,
            disable_email: false,
            is_otp_send: false,
            send_otp_loading: false,
            disable_otp: false,
            verify_email_loading: false,
            is_retired_army_personnel: false,
            disable_send_otp_button: false,
            disable_verify_otp_button: false,
            password: "",
            confirm_password: "",
            password_type: "password",
            confirm_password_type: "password",
            password_icon: "mdi mdi-eye",
            confirm_password_icon: "mdi mdi-eye",
            register_loading: false,
            register_button_disable: true,
            is_register_mah_cet: false,
            is_agree: false,



        };
    },
    mounted() {
        this.init();
    },
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        register() {


            if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
                this.showSnackbar("red", "Please Enter Valid Email.");
                return;
            }
            if (!this.email) {
                this.showSnackbar("red", "Please Enter Email.");
                return;
            }
            if (!this.is_email_verified) {
                this.showSnackbar("red", "PLease Verify Email First.");
                return;
            }
            if (!this.first_name) {
                this.showSnackbar("red", "PLease Enter First Name.");
                return;
            }

            if (!this.last_name) {
                this.showSnackbar("red", "PLease Enter Last Name.");
                return;
            }
            if (!this.mobile_no) {
                this.showSnackbar("red", "PLease Enter Mobile No.");
                return;
            }
          
            if (!this.password) {
                this.showSnackbar("red", "PLease Enter Password.");
                return;
            }
            if (!this.confirm_password) {
                this.showSnackbar("red", "PLease Enter Confirm Password.");
                return;
            }
            if (this.confirm_password != this.password) {
                this.showSnackbar("red", "Password And Confirm Password Should Be Same.");
                return;
            }
            if (this.password.length < 8) {
                this.showSnackbar("red", "Password Should Be Greater Than 8 Character.");
                return;
            }
            if (this.confirm_password.length < 8) {
                this.showSnackbar("red", "Confirm  Password Should Be Greater Than 8 Character.");
                return;
            }

            this.register_loading = true;
            var params = {
                "email": this.email,
                "first_name": this.first_name,
                "last_name": this.last_name,
                "middle_name": this.middle_name,
                "mobile_no": this.mobile_no,
                "parent_name": this.parent_name,
                "parent_rank": this.parent_rank,
                "mahcet_application_no": this.mah_cet_application_no,
                "password": this.password,
                "confirm_password": this.confirm_password,
              
            };
            const enc = crypto.encrypt(params);
            axios.post("Alumini/register", {
                params: enc,

            }).then((res) => {
                this.status = res.data.status;
                if (this.status == "200") {
                    this.$router.push("/");
                    this.showSnackbar("green", "Succesfully Registered.");
                    this.register_loading = false;
                    this.is_email_verified = false;

                } else {
                    this.showSnackbar("red", res.data.msg);
                    this.register_loading = false;
                }
            });

            return;
        },

        init() { },
        enable_register() {
            if (this.is_agree) {
                this.register_button_disable = false;
            }
            else {
                this.register_button_disable = true;
            }
        },
        update_password_icon(value) {
            if (value == 'password') {
                if (this.password_type == 'text') {
                    this.password_type = 'password';
                    this.password_icon = 'mdi mdi-eye';
                }
                else {
                    this.password_type = 'text';
                    this.password_icon = 'mdi mdi-eye-off';
                }

            }
            if (value == 'confirm_password') {
                if (this.confirm_password_type == 'text') {
                    this.confirm_password_type = 'password';
                    this.confirm_password_icon = 'mdi mdi-eye';
                }
                else {
                    this.confirm_password_type = 'text';
                    this.confirm_password_icon = 'mdi mdi-eye-off';
                }
            }
        },
        Sendotp() {
            this.send_otp_loading = true;
            if (!this.email) {
                this.showSnackbar("red", "Please Enter Email.");
                this.disable_email = false;
                this.send_otp_loading = false;
                return;
            }
            if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
                this.showSnackbar("red", "Please Enter Valid Email.");
                this.disable_email = false;
                this.send_otp_loading = false;
                return;
            }
            this.disable_email = true;
            var params = {
                "email": this.email,
            };
            const enc = crypto.encrypt(params);
            axios.post("Alumini/sendOTP", {
                params: enc,
            }).then((res) => {
                this.status = res.data.status;
                if (this.status == "200") {
                    this.showSnackbar("green", res.data.msg);
                    this.disable_email = true;
                    this.send_otp_loading = false;
                    this.is_otp_send = true;
                    this.disable_send_otp_button = true;
                } else {
                    this.showSnackbar("red", res.data.msg);
                    this.disable_email = false;
                    this.send_otp_loading = false;
                }
            });
        },
        verifyOTP() {

            if (!this.email) {
                this.showSnackbar("red", "Please Enter Email.");
                this.disable_email = false;
                this.send_otp_loading = false;
                return;
            }
            if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
                this.showSnackbar("red", "Please Enter Valid Email.");
                this.disable_email = false;
                this.send_otp_loading = false;
                return;
            }
            this.disable_email = true;
            this.disable_otp = true;
            this.verify_email_loading = true;
            var params = {
                "email": this.email,
                "otp": this.email_otp,
            };
            const enc = crypto.encrypt(params);
            axios.post("Alumini/verifyOTP", {
                params: enc,
            }).then((res) => {
                this.status = res.data.status;
                if (this.status == "200") {
                    this.showSnackbar("green", res.data.msg);
                    this.verify_email_loading = false;
                    this.disable_otp = true;
                    this.disable_verify_otp_button = true;
                    this.is_email_verified = true;
                } else {
                    this.showSnackbar("red", res.data.msg);
                    this.send_otp_loading = false;
                    this.disable_otp = false
                }
            });
        },

    },
};
</script>